import c0 from './auto_submit_period_select_controller';
import c1 from './availability/initial_search_controller';
import c2 from './consent/consent_management_controller';
import c3 from './initialize_controller';
import c4 from './openable_controller';
import c5 from './overlappable_controller';
import c6 from './period_select_controller';
import c7 from './pop_up_controller';
import c8 from './recaptcha_controller';
import c9 from './redirect_controller';
import c10 from './slideshow_controller';
import c11 from './sticky_controller';
import c12 from './turbo_opt_in_controller';
import c13 from './v2_pop_up_controller';
export const definitions = [
	{identifier: 'auto-submit-period-select', controllerConstructor: c0},
	{identifier: 'availability--initial-search', controllerConstructor: c1},
	{identifier: 'consent--consent-management', controllerConstructor: c2},
	{identifier: 'initialize', controllerConstructor: c3},
	{identifier: 'openable', controllerConstructor: c4},
	{identifier: 'overlappable', controllerConstructor: c5},
	{identifier: 'period-select', controllerConstructor: c6},
	{identifier: 'pop-up', controllerConstructor: c7},
	{identifier: 'recaptcha', controllerConstructor: c8},
	{identifier: 'redirect', controllerConstructor: c9},
	{identifier: 'slideshow', controllerConstructor: c10},
	{identifier: 'sticky', controllerConstructor: c11},
	{identifier: 'turbo-opt-in', controllerConstructor: c12},
	{identifier: 'v2-pop-up', controllerConstructor: c13},
];
