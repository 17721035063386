import React, { useEffect, useRef, useState, useMemo } from 'react';
import PeriodSelectorDay from './period_selector_day';

const DAY_FORMAT = 'dd';
const DISPLAY_FORMAT = 'DD-MM-YYYY';

export default function PeriodSelector({
  startDate: initialStartDate,
  endDate: initialEndDate,
  minDate: minDateProp,
  maxDate: maxDateProp,
  maxDuration,
  canReset,
  startDateDefaultText,
  endDateDefaultText,
  holidays,
  wrapperClass,
  onUpdateDropdownPosition,
  onSelect,
  onReset,
}) {
  const [mode, setMode] = useState('inactive');
  const [startDate, setStartDate] = useState(initialStartDate ? moment(initialStartDate) : null);
  const [endDate, setEndDate] = useState(initialStartDate && initialEndDate ? moment(initialEndDate) : null);
  const [hoverDate, setHoverDate] = useState(null);

  const [activeMonthDate, setActiveMonthDate] = useState(() => {
    if (initialStartDate) return moment(initialStartDate);
    if (minDateProp) return moment(minDateProp);
    return moment();
  });

  const [movingNext, setMovingNext] = useState(false);
  const [movingPrev, setMovingPrev] = useState(false);

  const minDate = minDateProp ? moment(minDateProp) : null;
  const maxDate = maxDateProp ? moment(maxDateProp) : null;

  const mouseDownRef = useRef(false);
  const timerRef = useRef(null);

  const debouncedUpdateDropdownPosition = debounce(onUpdateDropdownPosition, 100);

  function handleWindowMouseDown() {
    if (!mouseDownRef.current) {
      setMode('inactive');
    }
  }

  function handleMouseDown() {
    mouseDownRef.current = true;
  }

  function handleMouseUp() {
    mouseDownRef.current = false;
  }

  function makeActive(mode, event) {
    if (!startDate) {
      setMode('select_start');
    } else {
      setMode(mode);
    }
    event.stopPropagation();
  }

  function onResetButtonClick(event) {
    setStartDate(null);
    setEndDate(null);
    setMode('inactive');
    setActiveMonthDate(moment());
    onReset();
    event.stopPropagation();
  }

  function handleNextMonthClick() {
    setMovingNext(true);
    withDelay(handleNextMonthDone);
  }

  function handlePrevMonthClick() {
    setMovingPrev(true);
    withDelay(handlePrevMonthDone);
  }

  function withDelay(callback) {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
      callback();
    }

    timerRef.current = setTimeout(() => {
      timerRef.current = null;
      callback();
    }, 200);
  }

  function handleNextMonthDone() {
    moveDone(activeMonthDate.clone().add(1, 'months'));
  }

  function handlePrevMonthDone() {
    moveDone(activeMonthDate.clone().add(-1, 'months'));
  }

  function moveDone(newMonth) {
    setMovingNext(false);
    setMovingPrev(false);
    setActiveMonthDate(newMonth);
  }

  function handleSelectStartDate(day) {
    setStartDate(day);
    setEndDate(null);
    setMode('select_end');
  }

  function handleSelectEndDate(day) {
    setEndDate(day);
    setMode('inactive');
    onSelect(startDate, day);
  }

  useEffect(() => {
    window.addEventListener('mousedown', handleWindowMouseDown);
    window.addEventListener('scroll', debouncedUpdateDropdownPosition);
    onUpdateDropdownPosition();

    return () => {
      window.removeEventListener('mousedown', handleWindowMouseDown);
      window.removeEventListener('scroll', debouncedUpdateDropdownPosition);
    };
  }, []);

  const startDateClasses = [startDate ? '' : 'blank', mode == 'select_start' ? 'active' : ''];
  const endDateClasses = [endDate ? '' : 'blank', mode == 'select_end' ? 'active' : ''];
  const activeClass = mode == 'inactive' ? '' : 'is-active';
  const movingClass = movingNext ? 'moving-next' : movingPrev ? 'moving-prev' : '';

  const startDateText = startDate ? (
    <>
      <span className="day">{startDate.format(DAY_FORMAT)}</span> {startDate.format(DISPLAY_FORMAT)}
    </>
  ) : (
    startDateDefaultText
  );

  const endDateText = endDate ? (
    <>
      <span className="day">{endDate.format(DAY_FORMAT)}</span> {endDate.format(DISPLAY_FORMAT)}
    </>
  ) : (
    endDateDefaultText
  );

  return (
    <div onMouseDown={handleMouseDown} onMouseUp={handleMouseUp}>
      <div className={`enhanced-dropdown ${activeClass} ${wrapperClass || ''}`}>
        <div onClick={(event) => makeActive('select_start', event)}>
          <div className="enhanced-dropdown-status enhanced-dropdown-no-caret subselects">
            {canReset && <ResetButton onClick={onResetButtonClick} />}
            <div className="enhanced-dropdown-status-inner">
              <i className="fas fa-calendar-alt enhanced-dropdown-status-icon" onClick={(event) => makeActive('select_start', event)} />
              <span className="inline-flex flex-center flex-wrap py-1 full-width">
                <span className={`period-selector-date my-px ${startDateClasses.join(' ')}`} role="button">
                  {startDateText}
                </span>
                <i className="fas fa-long-arrow-alt-right mx-2.5 my-px" />
                <span
                  className={`period-selector-date my-px ${endDateClasses.join(' ')}`}
                  role="button"
                  onClick={(event) => makeActive('select_end', event)}
                >
                  {endDateText}
                </span>
              </span>
            </div>
          </div>
        </div>

        <div className="enhanced-dropdown-select">
          <div className="period-select calendar">
            <button
              className="p-0 w-9 h-9 flex-center border border-gray-400 hover:bg-gray-100 rounded-full period-select-next"
              onClick={handleNextMonthClick}
              type="button"
            >
              <i className="fa fa-caret-right w-4" />
            </button>
            <button
              className="p-0 w-9 h-9 flex-center border border-gray-400 hover:bg-gray-100 rounded-full period-select-prev"
              onClick={handlePrevMonthClick}
              type="button"
            >
              <i className="fa fa-caret-left w-4" />
            </button>
            <div className="period-select-months-container">
              <div className={`period-select-months ${movingClass}`}>
                <Months
                  activeMonthDate={activeMonthDate}
                  startDate={startDate}
                  endDate={endDate}
                  hoverDate={hoverDate}
                  mode={mode}
                  holidays={holidays}
                  minDate={minDate}
                  maxDate={maxDate}
                  maxDuration={maxDuration}
                  onSelectStartDate={handleSelectStartDate}
                  onSelectEndDate={handleSelectEndDate}
                  onHoverDate={setHoverDate}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function ResetButton({ onClick }) {
  return (
    <div className="pull-right enhanced-dropdown-op" onClick={onClick}>
      <i className="fa fa-times"></i>
    </div>
  );
}

function Months({
  activeMonthDate,
  startDate,
  endDate,
  hoverDate,
  mode,
  holidays,
  minDate,
  maxDate,
  maxDuration,
  onSelectStartDate,
  onSelectEndDate,
  onHoverDate,
}) {
  return (
    <>
      {[-1, 0, 1, 2].map((offset) => (
        <Month
          key={offset}
          monthDate={activeMonthDate.clone().add(offset, 'months')}
          startDate={startDate}
          endDate={endDate}
          hoverDate={hoverDate}
          mode={mode}
          holidays={holidays}
          minDate={minDate}
          maxDate={maxDate}
          maxDuration={maxDuration}
          onSelectStartDate={onSelectStartDate}
          onSelectEndDate={onSelectEndDate}
          onHoverDate={onHoverDate}
        />
      ))}
    </>
  );
}

function generateDayLabels() {
  const now = moment().startOf('week').add(-1, 'days');

  return (
    <>
      {[0, 1, 2, 3, 4, 5, 6].map((i) => (
        <td key={i} className="calendar-day-label">
          {now.add(1, 'days').format('dd')}
        </td>
      ))}
    </>
  );
}

function Month({
  monthDate,
  startDate,
  endDate,
  hoverDate,
  mode,
  holidays,
  minDate,
  maxDate,
  maxDuration,
  onSelectStartDate,
  onSelectEndDate,
  onHoverDate,
}) {
  const dayLabels = useMemo(() => generateDayLabels());

  return (
    <div className="calendar-month calendar-month-block period-select-month">
      <div className="calendar-month-title">{monthDate.format('MMMM YYYY')}</div>
      <table className="table-fixed">
        <tbody>
          <tr>{dayLabels}</tr>
          <Weeks
            monthDate={monthDate}
            startDate={startDate}
            endDate={endDate}
            hoverDate={hoverDate}
            mode={mode}
            holidays={holidays}
            minDate={minDate}
            maxDate={maxDate}
            maxDuration={maxDuration}
            onSelectStartDate={onSelectStartDate}
            onSelectEndDate={onSelectEndDate}
            onHoverDate={onHoverDate}
          />
          <PlaceholderRows monthDate={monthDate} />
        </tbody>
      </table>
    </div>
  );
}

function weekDates(monthDate) {
  let currentDate = monthDate.clone().startOf('month').startOf('week');
  let weekDates = [];

  while (currentDate.isSameOrBefore(monthDate, 'month')) {
    weekDates.push([0, 1, 2, 3, 4, 5, 6].map((i) => currentDate.clone().add(i, 'days')));
    currentDate.add(7, 'days');
  }

  return weekDates;
}

function Weeks({
  monthDate,
  startDate,
  endDate,
  hoverDate,
  mode,
  holidays,
  minDate,
  maxDate,
  maxDuration,
  onSelectStartDate,
  onSelectEndDate,
  onHoverDate,
}) {
  return (
    <>
      {weekDates(monthDate).map((week) => (
        <tr key={week[0].format('YYYY-MM-DD')}>
          {week.map((day) => (
            <PeriodSelectorDay
              key={day.format('YYYY-MM-DD')}
              startDate={startDate}
              endDate={endDate}
              hoverDate={hoverDate}
              mode={mode}
              holidays={holidays}
              day={day}
              monthDate={monthDate}
              minDate={minDate}
              maxDate={maxDate}
              maxDuration={maxDuration}
              onSelectStartDate={onSelectStartDate}
              onSelectEndDate={onSelectEndDate}
              onHoverDate={onHoverDate}
            />
          ))}
        </tr>
      ))}
    </>
  );
}

function PlaceholderRows({ monthDate }) {
  const rowsCount = 6 - weekDates(monthDate).length;

  return (
    <tr>
      {[...Array(rowsCount)].map((_, i) => (
        <PlaceholderRow key={i} />
      ))}
    </tr>
  );
}

function PlaceholderRow() {
  return (
    <>
      {[0, 1, 2, 3, 4, 5, 6].map((i) => (
        <td key={i} className="calendar-placeholder" style={{ border: '1px transparent solid' }} />
      ))}
    </>
  );
}
