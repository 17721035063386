import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['trigger'];
  static values = { key: String, isPreview: Boolean, triggeredBy: String };

  connect() {
    if (this.isPreview() || this.triggeredByValue === 'button') {
      this.showModal();
    } else {
      if (this.wasNotSeenYet()) {
        this.showModal();
        this.markSeen();
      }
    }
  }

  showModal() {
    this.triggerTarget.click();
  }

  isPreview() {
    return !!this.isPreviewValue;
  }

  markSeen() {
    let seenPopUps = this.getSeenPopUps();
    seenPopUps.push(this.keyValue);
    localStorage.setItem('seen_pop_ups', JSON.stringify(seenPopUps));
  }

  wasNotSeenYet() {
    return !this.getSeenPopUps().includes(this.keyValue);
  }

  getSeenPopUps() {
    return JSON.parse(localStorage.getItem('seen_pop_ups')) || [];
  }
}
