import '@hotwired/turbo-rails';
import { Application } from '@hotwired/stimulus';
import { definitions } from 'stimulus:./controllers';

import AutosubmitController from '@bookingexperts/design-system/app/javascript/design_system/stimulus_controllers/autosubmit_controller';
import ApplicationLayoutController from '@bookingexperts/design-system/app/javascript/design_system/stimulus_controllers/application_layout_controller';
import ModalController from '@bookingexperts/design-system/app/javascript/design_system/stimulus_controllers/modal_controller';
import ModalDialogController from '@bookingexperts/design-system/app/javascript/design_system/stimulus_controllers/modal_dialog_controller';
import ModalTriggerController from '@bookingexperts/design-system/app/javascript/design_system/stimulus_controllers/modal_trigger_controller';
import ShowByController from '@bookingexperts/design-system/app/javascript/design_system/stimulus_controllers/show_by_controller';
import ExpandableController from '@bookingexperts/design-system/app/javascript/design_system/stimulus_controllers/expandable_controller';
import PopoverController from '@bookingexperts/design-system/app/javascript/design_system/stimulus_controllers/popover_controller';
import DateRangeCalendarController from '@bookingexperts/design-system/app/javascript/design_system/stimulus_controllers/date_range_calendar_controller';
import '@bookingexperts/design-system/app/javascript/design_system/modules/turbo_frame';
import '@bookingexperts/design-system/app/javascript/design_system/modules/turbo_stream';

import '@bookingexperts/waf';
import './modules/turbo';

export const application = Application.start();
window.Stimulus = application;

application.load(definitions);
application.register('autosubmit', AutosubmitController);
application.register('application-layout', ApplicationLayoutController);
application.register('modal', ModalController);
application.register('modal-dialog', ModalDialogController);
application.register('modal-trigger', ModalTriggerController);
application.register('show-by', ShowByController);
application.register('expandable', ExpandableController);
application.register('popover', PopoverController);
application.register('date-range-calendar', DateRangeCalendarController);

window.Turbo.session.drive = false;
